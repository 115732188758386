import React from 'react';
import style from './HeaderBanner.module.scss';
import InputForm from '../../Elements/InputForm';

const HeaderBanner = () => {
  return (
    <section className={style.headerBanner}>
      <div className={style.headerBanner__line}>
        <div className={style.container}>
          <article>
            <h1>Fast Courier Service</h1>
            <p>Worldwide courier services</p>
          </article>
          <InputForm></InputForm>
        </div>
      </div>
    </section>
  );
};

export default HeaderBanner;
