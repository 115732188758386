import Footer from '../Footer';
import Header from '../Header';
import style from './PageTemplate.module.scss';

const PageTemplate = ({children}: any) => {
  return (
    <>
      <Header></Header>
      <main className={style.main}>{children}</main>
      <Footer></Footer>
    </>
  );
};

export default PageTemplate;
