import React from 'react';
import style from './CardService.module.scss';
import { ReactComponent as TruckDeliveryIcon } from '../../../assets/icons/cardIcons/delivery-truck-delivery-svgrepo-com.svg';
import { ReactComponent as EarthIcon } from '../../../assets/icons/cardIcons/earth-globe-planet-svgrepo-com.svg';
import { ReactComponent as ShieldIcon } from '../../../assets/icons/cardIcons/shield-antivirus-svgrepo-com.svg';

const CardService = () => {
  return (
    <section className={style.cardService}>
      <div className={style.container}>
        <ul>
          <li>
            <TruckDeliveryIcon className={style.icon}/>
            <h2>Fast</h2>
            <p>
              If you want to send your parcel to a specific location within a
              certain timeframe, you should opt for professional courier
              service. <br />Here we are.
            </p>
          </li>
          <li>
            <EarthIcon className={style.icon}/>
            <h2>Worldwide</h2>
            <p>
              Sending parcel from one city to another is not a problem anymore.
              From door to door, country to country, you can count on us. <br />We got
              it covered.
            </p>
          </li>
          <li>
            <ShieldIcon className={style.icon}/>
            <h2>Safe</h2>
            <p>
              We offer insurance for every shipment. Our services ensure the
              safe and timely delivery of your package. <br />Guaranteed.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default CardService;
