import React from 'react';
import style from './AboutCompany.module.scss';

const AboutCompany = () => {
  return (
    <section className={style.aboutCompany}>
      <div className={style.container}>
        <article className={style.cardAboutCompany}>
          <h2>About company</h2>
          <p>
            Continent Express is offering you a wide range of express delivery
            services of high quality. Ever since the company was set up in 1998,
            it has grown to become one of the leaders of transport and
            forwarding market by providing its customers with the best possible
            shipping efficiency. We employ over 2000 specialists in our branch
            offices in United States, France, Spain, United Kingdom, Italy,
            Canada and many other countries. We provide our customers with a
            wide range of services including Air Freight, Import/Export
            Transportation, Transport Service throughout Russia and CIS,
            Overnight Delivery, Customs Clearance Services.
          </p>
        </article>
      </div>
    </section>
  );
};

export default AboutCompany;
