import React from 'react';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import InputForm from '../../components/Elements/InputForm';
import style from './TrackPage.module.scss';
import TrackResult from '../../components/Elements/TrackResult';
import Button from '../../components/Elements/Button';
import { Link } from 'react-router-dom';

const TrackPage = () => {
  const handleBack = () => {};
  return (
    <PageTemplate>
      <div className={style.container_bg}>
        <div className={style.container}>
          <Link to="/">
            <Button>Home</Button>
          </Link>
          <div className={style.content}>
            <TrackResult />
            <InputForm />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default TrackPage;
