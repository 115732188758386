import React, { useState } from 'react';
import style from './InputForm.module.scss';
import { ReactComponent as LoopIcon } from '../../../assets/icons/search-svg-repo-com.svg';
import { Link, useNavigate } from 'react-router-dom';

const InputForm = () => {
  const [inputValue, setInputValue] = useState('');
  const [inputValueDirty, setInputValueDirty] = useState(false);
  const [inputValueError, setInputValueError] = useState('');
  const navigate = useNavigate();

  const blurHandler = (event: any) => {
    if (event.target.name === 'track' && event.target.value === '') {
      setInputValueError('');
      setInputValueDirty(true);
    } else if (event.target.name === 'track' && inputValue.length !== 10) {
      setInputValueError('Enter the track number.');
      setInputValueDirty(true);
    } else {
      setInputValueDirty(false);
    }
  };

  const handleChange = (event: any) => {
    const value = event.target.value.replace(/\D/g, '');
    setInputValue(value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (inputValue.trim() === '') {
      setInputValueError('Please enter a valid track number.');
      return;
    } else if (inputValue.length !== 10) {
      setInputValueError('Enter the track number.');
      return;
    }
    setInputValue('');
    navigate(`/track/${inputValue}`);
  };

  return (
    <div className={style.inputForm}>
      <p>Track your parcel</p>
      <form onSubmit={handleSubmit}>
        <label>
          {inputValueDirty && inputValueError && (
            <div className={style.errorMessage}>{inputValueError}</div>
          )}
          <input
            type="text"
            name="track"
            value={inputValue}
            onBlur={blurHandler}
            onChange={handleChange}
            placeholder="Enter track number"
            inputMode="numeric"
          />
        </label>
        <button type="submit">
          <LoopIcon />
        </button>
      </form>
    </div>
  );
};

export default InputForm;
