import React from 'react';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import HeaderBanner from '../../components/Modules/HeaderBanner';
import CardService from '../../components/Modules/CardService';
import AboutCompany from '../../components/Modules/AboutCompany';
import SolutionsList from '../../components/Modules/SolutionsList';

const HomePage = () => {
  return (
    <PageTemplate>
      <HeaderBanner></HeaderBanner>
      <CardService></CardService>
      <AboutCompany></AboutCompany>
      <SolutionsList></SolutionsList>
    </PageTemplate>
  );
};

export default HomePage;
