import React from 'react';
import style from './Header.module.scss';
import TelegramIcon from '../../Elements/TelegramIcon';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className={style.header}>
      <div className={style.container}>
        <Link to="/" className={style.logo}>
          Continental Courier Express
        </Link>
      </div>
    </header>
  );
};

export default Header;
