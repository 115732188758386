import React from 'react';
import style from './Button.module.scss';

const Button = ({ onClick, children }: any) => {
  return (
    <button className={style.button} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
