import React from 'react';
import style from './NotFoundPage.module.scss';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import { Link } from 'react-router-dom';
import Button from '../../components/Elements/Button';

const NotFoundPage = () => {
  return (
    <PageTemplate>
      <div className={style.container}>
        <h2>404</h2>
        <p>This page could not be found</p>
        <p>Go back to <Link to='/'>home page</Link></p>
      </div>
    </PageTemplate>
  );
};

export default NotFoundPage;
