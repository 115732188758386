import React, { FC, useEffect, useState } from 'react';
import style from './TrackResult.module.scss';
import { useParams } from 'react-router-dom';

interface IProductInfo {
  invoice: string;
  date: string;
  path: string;
}

interface IUserData {
  Date: string;
  DepartureCity: string;
  DestinationCity: string;
  Recipients: string;
  Sender: string;
  TrackNumber: string;
  MyArray: IProductInfo[];
}

const TrackResult: FC = () => {
  const [data, setData] = useState<IUserData[]>([]);
  const [error, setError] = useState<string>('');

  const { number } = useParams();
  const trackNumber = number; // уже строка из параметров URL

  useEffect(() => {
    if (!trackNumber) return;
    
    fetch(
      `https://66995d4f2069c438cd721889.mockapi.io/api/tracks/${trackNumber}`
    )
      .then((response) => {
        if (!response.ok) {
          setData([]);
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then((responseData: IUserData) => {
        if (!responseData) {
          setError('This track does not exist. Enter your track number.');
          setData([]);
        } else {
          setError('');
          setData([responseData]);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again later.');
      });
  }, [trackNumber]);

  return (
    <section className={style.trackResult}>
      <p>Check result: </p>
      {error && <div className={style.error}>{error}</div>}
      {data.map((userData: IUserData) => (
        <article key={userData.TrackNumber}>
          <ul className={style.userInfo}>
            <li>Date: {userData.Date}</li>
            <li>Sender: {userData.Sender}</li>
            <li>DepartureCity: {userData.DepartureCity}</li>
            <li>DestinationCity: {userData.DestinationCity}</li>
            <li>Recipients: {userData.Recipients}</li>
          </ul>
          <div className={style.underLine}></div>
          {userData.MyArray.map((productInfo: IProductInfo, index: number) => (
            <div key={index}>
              <ul className={style.userProductInfo}>
                <li>Invoice: №{productInfo.invoice}</li>
                <li>Date: {productInfo.date}</li>
                <li>Path: {productInfo.path}</li>
              </ul>
              <div className={style.underLine}></div>
            </div>
          ))}
        </article>
      ))}
    </section>
  );
};

export default TrackResult;
