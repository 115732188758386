import React from 'react'
import style from './SolutionsList.module.scss'

const SolutionsList = () => {
  return (
    <section className={style.solutionsList}>
        <div className={style.container}>
            <ul>
                <li>
                    <h3>Shipping</h3>
                    <p>Services and technology for managing your shipping process—from individuals to enterprises, local to global.</p>
                </li>
                <li>
                    <h3>Tracking</h3>
                    <p>Monitoring and management solutions for individuals, complex global supply chains, and everyone in between.</p>
                </li>
                <li>
                    <h3>Contract Logistics</h3>
                    <p>Expertise and infrastructure to optimize your supply chain, from global delivery to warehousing and returns management.</p>
                </li>
                <li>
                    <h3>International Trade</h3>
                    <p>Services, resources, and technologies to help get your products through customs to market more quickly.</p>
                </li>
                <li>
                    <h3>Customer Solutions</h3>
                    <p>Collaborate with UPS experts to create a customized plan of services, solutions, and technology tools to meet your unique business challenges.</p>
                </li>
                <li>
                    <h3>E-Commerce</h3>
                    <p>Find shipping services and technology tools to enhance your online store or to create one from scratch.</p>
                </li>
            </ul>
        </div>
    </section>
  )
}

export default SolutionsList