import style from './Footer.module.scss';
import { Link } from 'react-router-dom';
import TelegramIcon from '../../Elements/TelegramIcon';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.container}>
        <ul>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
          <li>© Continental Courier Express 2024. All Rights Reserved.</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
