import style from './PrivacyPage.module.scss';
import PageTemplate from '../../components/ModulesForPages/PageTemplate';
import { Link } from 'react-router-dom';
import Button from '../../components/Elements/Button';

const PrivacyPage = () => {
  return (
    <PageTemplate>
      <div className={style.container_bg}>
        <div className={style.container}>
          <Link to="/">
            <Button>Home</Button>
          </Link>
          <div className={style.content}>
            <div>Privacy policy</div>
            <div>
              <h2>Privacy policy</h2>
              <p>
                We are committed to working in accordance with the provisions of
                the UK Data Protection Act, and European General Data Protection
                Regulations (GDPR). Our policies and procedures are aimed at
                ensuring the Privacy and Security of any Personal Data/Personal
                Information we may collect about you.
              </p>
              <p>
                This Privacy Policy lets you know about the collection, use,
                disclosure and storage of Personal Data that you may provide to
                us. Examples of Personal Data include information that we
                collect from application forms and other communications with us
                and may include identity details, names/ addresses, e-mail
                details telephone numbers, and contract job and delivery
                details.
              </p>
              <p>
                We collect this information/Personal Data so that we can respond
                to your enquiries and so that we can provide you with the best
                possible service.
              </p>
              <p>
                We do not use your Personal Data for marketing purposes and will
                not do without your express consent. We will not collect
                sensitive information about you without your consent.
              </p>
              <h3>Collection of Personal Information</h3>
              <p>
                Continental Courier Express collects and stores information
                about the contracts jobs and deliveries that we do. We use
                information about our customers, their packages, and their
                dispatch/transport activity to provide or enhance the services
                we provide, to communicate with customers about service levels
                and any additional services they may find of value, to satisfy
                our legitimate business interests (including performing trend
                analysis and market studies), to set prices, to open accounts
                and establish account holder credit limits, perform the billing
                function, and comply with government regulations.
              </p>
              <h3>Access to Your Personal Data</h3>
              <p>
                You can find out what Personal Data we hold by contacting us,
                using the details below. Should we for any reason be prevented
                from disclosing your personal information we will give reasons
                for any such refusal. If any of our information is incorrect
                please advise us of the correct details and changes required,
                using the contact details below and we will update or correct
                Personal Data in our possession.
              </p>
              <h3>Our Retention of Your Personal Data</h3>
              <p>
                We will not keep your Personal Data for longer than is
                necessary; We are however obliged to keep records for a
                reasonable period; Our Policy for destruction of Data is 8 years
                after the end of any business relationship with you.
              </p>
              <h3>Cookies</h3>
              <p>
                When you view this website, we may store some information on
                your computer. This will be in the form of a cookie file. We use
                cookies to store information which we may use to improve the
                content of our site and to customize the site to your
                preferences.
              </p>
              <p>
                Cookies are small text files that a website can send to a user’s
                browser for storage on the hard drive. Cookies can make use of
                the web easier by saving and administering status, application,
                preferences and another user information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default PrivacyPage;
